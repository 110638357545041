import { Colors } from '@/shared/util/colors';
import { Box, Container } from '@mui/material';
import TextWithIcon from '../default-components/text-with-icon/text-with-icon';
import CheckMark from '@/shared/icons/checkmark-green.svg';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import { useAppDispatch } from '@/shared/store/hooks';
import { resetFlow } from '@/shared/store/reducers/SYISlice';
import { useRouter } from 'next/router';
import { useScreenSizeContext } from '@/pages/_app';

const Data = [
  'SRP.SubTopBar.FirstText',
  'SRP.SubTopBar.SecondText',
  'SRP.SubTopBar.ThirdText',
  'SRP.SubTopBar.FourthText',
];

export const SRPSubTopBar = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const router = useRouter();
  const itemText = useMemo(
    // eslint-disable-next-line react/display-name
    () => (d: string, itemIndex: number) =>
      (
        <TextWithIcon
          key={itemIndex}
          icon={CheckMark.src}
          iconHeight={9}
          iconWidth={12}
          text={t(d)}
          textcolor={Colors.White}
        />
      ),
    [t],
  );

  const subTopBars = useMemo(
    () =>
      Data.map((d, itemIndex, array) => {
        if (itemIndex === array.length - 1) {
          return (
            <Link
              key={itemIndex}
              onClick={e => {
                if (router.route !== '/saelg-bil') {
                  dispatch(resetFlow());
                }
              }}
              href="/saelg-bil">
              {itemText(d, itemIndex)}
            </Link>
          );
        } else if (itemIndex === 0) {
          return (
            <Link
              key={itemIndex}
              target="_blank"
              href="https://dk.trustpilot.com/review/bilhandel.dk">
              {itemText(d, itemIndex)}
            </Link>
          );
        } else {
          return itemText(d, itemIndex);
        }
      }),
    [itemText, dispatch, router],
  );
  const screenSize = useScreenSizeContext();
  return (
    <>
      {!screenSize.isMobile && (
        <Box
          display={'flex'}
          alignItems={'center'}
          height={35}
          width={'100%'}
          bgcolor={Colors.DarkBlue}>
          <Container>
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-evenly'}>
              {subTopBars}
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default SRPSubTopBar;
